var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.submit.apply(null, arguments)
        }
      }
    },
    [
      _c("h2", { staticClass: "form-title" }, [_vm._v("Enter your email")]),
      _c("p", { staticClass: "form-sub-title" }, [
        _vm._v("Inqli will help you find your peers.")
      ]),
      this.error
        ? _c("div", { staticClass: "error-message" }, [
            _vm._v(_vm._s(this.error))
          ])
        : _vm._e(),
      _c(
        "div",
        { staticClass: "form-content" },
        [
          _c("v-text-field", {
            attrs: {
              label: "Email",
              rules: [
                function(v) {
                  return !!v || "Please enter your email."
                },
                function(v) {
                  return !_vm.$v.$invalid || "Please enter a valid email."
                }
              ],
              error: _vm.invalid
            },
            model: {
              value: _vm.email,
              callback: function($$v) {
                _vm.email = typeof $$v === "string" ? $$v.trim() : $$v
              },
              expression: "email"
            }
          }),
          _c(
            "div",
            { staticClass: "form-buttons" },
            [
              _c(
                "text-button",
                { attrs: { width: "40%" }, on: { click: _vm.skip } },
                [_vm._v(" Skip ")]
              ),
              _c(
                "inqli-button",
                {
                  attrs: {
                    width: "40%",
                    isProcess: _vm.isProcessing,
                    type: "submit"
                  }
                },
                [_vm._v("Submit")]
              )
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }