var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "app-layout",
    { attrs: { showTopBanner: false, showSidebar: false } },
    [
      _c("app-header", {
        attrs: { leftLogoPersist: true, showMobileMenuButton: false }
      }),
      _c(
        "email-prompt-card",
        [
          _vm.showVerifyForm
            ? _c("email-verification-form", { attrs: { email: _vm.email } })
            : _c("email-prompt-form", {
                attrs: { doneSubmit: _vm.finishSubmitEmail }
              })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }