<template>
  <form @submit.prevent="submit">
    <h2 class="form-title">Enter Verification code</h2>
    <p class="form-sub-title">
      Check your mailbox at
      <span class="inqliPurple--text">{{ email }}</span> to get the code.
    </p>
    <div v-if="this.error" class="error-message">{{ this.error }}</div>
    <div class="form-content">
      <v-text-field
        v-model.trim="code"
        label="Verification code"
        :rules="[(v) => !!v || 'Please enter verification code.']"
        :error="invalid"
      />
      <div class="form-buttons">
        <outline-button width="40%" @click="skip"> Cancel </outline-button>
        <inqli-button width="40%" :isProcess="isProcessing" type="submit"
          >Submit</inqli-button
        >
      </div>
      <text-button class="mt-2" @click="reSendVerificationCode"
        >Resend verification code</text-button
      >
    </div>
  </form>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { Auth } from "aws-amplify";
import InqliButton from "../../app/components/buttons/Button.vue";
import OutlineButton from "../../app/components/buttons/OutlineButton.vue";
import TextButton from "../../app/components/buttons/TextButton.vue";
import sendRequest from "../../app/utils/send-request-helper";
import isApplicableDomain from "../../app/utils/applicable-domain-helper";

export default {
  components: {
    "inqli-button": InqliButton,
    "outline-button": OutlineButton,
    "text-button": TextButton,
  },
  data() {
    return {
      code: "",
      isProcessing: false,
      invalid: false,
      error: "",
    };
  },
  props: {
    email: {
      type: String,
    },
  },
  validations: {
    code: {
      required,
    },
  },
  methods: {
    /**
     * Later on we may need to allow user change their email or retake sending verificaiton code
     */
    async submit() {
      if (!this.isProcessing) {
        this.isProcessing = true;
        this.error = null;
        if (this.$v.$invalid) {
          this.invalid = true;
        } else {
          try {
            const doneVerify = await this.verify();
            if (doneVerify) {
              // update user email
              await this.updateUserEmail();
              // check orgs
              const userOrg = await this.checkUserOrg();
              if (userOrg) {
                this.$router.push(`/users/${this.$store.state.user.username}`);
              } else {
                const emailDomain = this.email.split("@")[1];
                const org = await this.checkOrgByDomain(emailDomain);
                if (org) {
                  this.$router.push(`/organizations/join`);
                } else if (isApplicableDomain(emailDomain)) {
                  // check user domain
                  this.$router.push(`/organizations/create`);
                } else {
                  this.$router.push(
                    `/users/${this.$store.state.user.username}`
                  );
                }
              }
            }
          } catch (err) {
            this.$router.push("/users/" + this.$store.state.user.username);
          }
        }
        this.isProcessing = false;
      }
    },
    async reSendVerificationCode() {
      try {
        await Auth.verifyCurrentUserAttribute("email");
        this.$store.dispatch(
          "callAlert",
          "Verification code has been sent to your email."
        );
      } catch (err) {
        this.$store.dispatch(
          "callAlert",
          "Can not send verification code to your email."
        );
      }
    },
    async checkOrgByDomain(domain) {
      const data = await sendRequest({
        url: `/resources/organizations/domain/${domain}`,
        method: "GET",
      });
      return data;
    },
    async verify() {
      try {
        await Auth.verifyCurrentUserAttributeSubmit("email", this.code);
        return true;
      } catch (e) {
        this.error = e.message;
        return false;
      }
    },
    async checkUserOrg() {
      await this.$store.dispatch("loadUserOrganization");
      const orgs = this.$store.state.user.organizations;
      return Array.isArray(orgs) && orgs.length > 0;
    },
    async updateUserEmail() {
      const updatedUserInfo = await sendRequest({
        url: `/resources/users/${this.$store.state.user.username}`,
        method: "PUT",
        isAuth: true,
        body: {
          email: this.email,
        },
      });
      this.$store.commit("setUserInfo", updatedUserInfo);
    },
    skip() {
      this.$router.push("/users/" + this.$store.state.user.username);
    },
  },
};
</script>
<style lang="scss" scoped>
.form-content {
  max-width: 400px;
  margin: auto;
}
.form-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  margin-bottom: 10px;
}
.error-message {
  color: grey;
  color: $inqliRed;
  line-height: 24px;
  margin-bottom: 10px;
}
.message {
  font-weight: bold;
  font-size: 18px;
}
.form-sub-title,
.error-message {
  padding: 0 20px;
}
</style>
