<template>
  <form @submit.prevent="submit">
    <h2 class="form-title">Enter your email</h2>
    <p class="form-sub-title">Inqli will help you find your peers.</p>
    <div v-if="this.error" class="error-message">{{ this.error }}</div>
    <div class="form-content">
      <v-text-field
        v-model.trim="email"
        label="Email"
        :rules="[
          (v) => !!v || 'Please enter your email.',
          (v) => !$v.$invalid || 'Please enter a valid email.',
        ]"
        :error="invalid"
      />
      <div class="form-buttons">
        <text-button width="40%" @click="skip"> Skip </text-button>
        <inqli-button width="40%" :isProcess="isProcessing" type="submit"
          >Submit</inqli-button
        >
      </div>
    </div>
  </form>
</template>

<script>
import { required, email } from "vuelidate/lib/validators";
import { Auth } from "aws-amplify";
import InqliButton from "../../app/components/buttons/Button.vue";
import TextButton from "../../app/components/buttons/OutlineButton.vue";

export default {
  components: {
    "inqli-button": InqliButton,
    "text-button": TextButton,
  },
  data() {
    return {
      email: "",
      isProcessing: false,
      invalid: false,
      error: "",
    };
  },
  props: {
    doneSubmit: {
      type: Function,
    },
  },
  validations: {
    email: {
      required,
      email,
    },
  },
  methods: {
    async submit() {
      if (!this.isProcessing) {
        this.isProcessing = true;
        this.error = null;
        if (this.$v.$invalid) {
          this.invalid = true;
        } else {
          try {
            const currentUser = await Auth.currentAuthenticatedUser();
            await Auth.updateUserAttributes(currentUser, {
              email: this.email,
            });
            this.doneSubmit(this.email);
          } catch (err) {
            this.error =
              "Can not send verification code to your email, please try again.";
          }
        }
        this.isProcessing = false;
      }
    },
    skip() {
      this.$router.push("/users/" + this.$store.state.user.username);
    },
  },
};
</script>
<style lang="scss" scoped>
.form-content {
  max-width: 400px;
  margin: auto;
}
.form-sub-title,
.error-message {
  padding: 0 5px;
}
.form-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  margin-bottom: 10px;
}
.error-message {
  color: grey;
  color: $inqliRed;
  line-height: 24px;
  margin-bottom: 10px;
}
.message {
  font-weight: bold;
  font-size: 18px;
}
</style>
