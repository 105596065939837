<template>
  <v-card class="container" rounded="xl">
    <h1 class="title">Get the Most Out of Your Experience</h1>
    <quote />
    <slot></slot>
  </v-card>
</template>
<script>
import Quote from "../../app/components/Quote.vue";
export default {
  name: "EmailPromptCard",
  components: {
    quote: Quote,
  },
};
</script>
<style lang="scss" scoped>
.container {
  max-width: 600px;
  width: 70%;
  flex: 1;
  margin: 0 auto;
  margin-top: 45px;
  padding: 35px 64px;
  .title {
    color: $inqliGreen;
    font-size: 25px !important;
  }
}
@media screen and (max-width: $breakpoint-mobile) {
  .container {
    width: 95%;
    padding: 30px 26px;
    margin-top: 20px;
    margin-bottom: 30px;
  }
}
</style>
