var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "container", attrs: { rounded: "xl" } },
    [
      _c("h1", { staticClass: "title" }, [
        _vm._v("Get the Most Out of Your Experience")
      ]),
      _c("quote"),
      _vm._t("default")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }