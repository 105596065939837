<template>
  <app-layout :showTopBanner="false" :showSidebar="false">
    <app-header :leftLogoPersist="true" :showMobileMenuButton="false" />
    <email-prompt-card>
      <email-verification-form v-if="showVerifyForm" :email="email" />
      <email-prompt-form :doneSubmit="finishSubmitEmail" v-else />
    </email-prompt-card>
  </app-layout>
</template>

<script>
import Header from "../app/components/headers/DefaultHeader";
import Layout from "../app/components/layouts/DefaultLayout.vue";
import EmailPromptForm from "./components/EmailPromptForm.vue";
import EmailVerificationForm from "./components/EmailVerificationForm.vue";
import EmailPromptCard from "./components/EmailPromptCard.vue";

export default {
  components: {
    "app-header": Header,
    "app-layout": Layout,
    "email-prompt-form": EmailPromptForm,
    "email-prompt-card": EmailPromptCard,
    "email-verification-form": EmailVerificationForm,
  },
  metaInfo: {
    title: "Email Prompt",
  },
  data() {
    return {
      email: "",
      showVerifyForm: false,
    };
  },
  methods: {
    finishSubmitEmail(email) {
      this.email = email;
      this.showVerifyForm = true;
    },
  },
};
</script>
